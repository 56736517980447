/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/video.js@7.21.7/dist/video.min.js
 * - /npm/video.js@7.21.7/dist/lang/zh-TW.min.js
 * - /npm/videojs-contrib-quality-levels@2.2.1/dist/videojs-contrib-quality-levels.min.js
 * - /npm/videojs-http-source-selector@1.1.6/dist/videojs-http-source-selector.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
